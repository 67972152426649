@media screen and (max-width: 640px) {
    .grid-item > .tap {
        max-height: 28vw !important;
        margin-top: 7% !important;
        margin-right: 105% !important;
    }

    .grid-item > .tap {
        max-height: 28vw;
    }
}