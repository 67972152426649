.sketchModal {
    cursor: pointer;
    margin: 0;
    padding: 0;
    background: rgba(38, 38, 38, 0.71);
    position: fixed;
    flex-direction: column;
    width: 100svw;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: visiblePainted;
}
.sketch-picker {
    cursor: auto;
}