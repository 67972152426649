@media screen and (max-device-width: 640px) {
    .beerName {
        font-size: 2.0vmin;
    }
    .beerBrewery {
        font-size: 1.8vmin;
    }
    .beerInfo {
        font-size: 1.6vmin;
    }
}
