.quizTablesContainer {
    overflow: auto !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    padding: 25px;
}

.quizTablesInfoContainer {
    text-align: start;
    width: fit-content;
}
.quizTablesInfoContainer h2 {
    margin-bottom: 0px;
}
.quizTablesInfoContainer li button {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    filter: brightness(100%);
    transition: 0.1s;
}
.quizTablesInfoContainer li {
    margin-bottom: 3px;
}
.quizTablesInfoContainer li button:hover {
    cursor: pointer;
    filter: brightness(75%);
    transition: 0.1s;
}

.quizTablesOuterContainer h1 {
    margin-bottom: 20px;
}
.quizTablesOuterContainer input, .quizTablesOuterContainer select {
    font-size: 2.3vh;
    font-family: Bely, sans-serif;
    border: grey 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: fit-content;
    margin: 10px;
    box-sizing: border-box;
    z-index: 2;
}

.manualBookingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.layoutBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.updateQuizInnerContainer {
    justify-content: center;
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}