body {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
  /*scrollbar-gutter: stable both-edges;*/
}

.App, .home {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100svh;
  width: 100svw;
  max-width:100%;  /* added */
  /*overflow-x: hidden;*/
  /*overflow: hidden;*/
}

.home {
  /*overflow-y: scroll;*/
}
.test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.beerLogo {
  width: 400px;
  height: 400px;
}


/* width */
::-webkit-scrollbar {
  width: 12px;
  transition: 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  transition: 0.2s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d95690;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #943b63;
  transition: 0.2s;
}