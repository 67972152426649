.outerUpdateEventsContainer {
    padding-bottom: 25px;
}
.updateEventsContainer {
    grid-template-rows: repeat(2, auto);
}
.updatePosterContainer {
    transition: 0.2s;
}


.updatePosterContainer img {
}

.updatePosterContainer {
    padding: 15px;
    height: auto;
    width: 12vw;
    transition: 0.2s;
}
.updatePosterContainer:hover {
    cursor: pointer;
    transition: 0.2s;
}

.updatePosterContainer img {
    border: 2px solid #DC5792;
    object-fit: cover;
    width: inherit;
    aspect-ratio: 4/3;
    height: 16vw;
    background-size: cover;
    background-position: center;
    filter: grayscale(0%) brightness(100%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(1);
    transition: 0.2s;
}
.updateEventsContainer img:hover {
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    transition: 0.2s;
}