.reviewsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.reviewCard {
    cursor: grab;
    /*width: 100%;*/
    width: 35vw;
    /*margin: 0 auto;*/
    display: inline-block;
    flex-direction: column;
    justify-content: start;
    border: 1px solid #b9b9b9;
    box-shadow: 2px 2px 2px rgba(117, 117, 117, 0.25);
    border-radius: 25px;
    padding: 20px;
    margin: 15px;
    height: auto;
}
.slick-slider {
    width: 100%;
}

.reviewHeader {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.reviewProfileInfo {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
}
.reviewProfileName {
    font-weight: bold;
}
.reviewPublishTime {
    color: #7a7a7a;
}

.reviewFooter {
    /*white-space: break-spaces;*/
    /*text-align: start;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: start;*/
}

.reviewProfilePic {
    width: 50px;
    cursor: pointer;
}
.reviewText {
    text-align: start;
    font-size: 2vh
}

.slick-track {
    display: flex !important;
    align-items: center;
}