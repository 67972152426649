.beerGridName {
    font-family: 'KCIllHand', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.2s;
    /*letter-spacing: 0.5px;*/
}
.beerName {
    /*font-weight: bold;*/
    line-height: 1;
    margin: 0 10px 0 10px;
    font-size: 1.4vmin;
}
.beerBrewery {
    margin: 0 10px 0 10px;
    font-size: 1.0vmin;
}
.beerNameDivider {
    height: 0.5px;
    background-color: #074524;
    margin: 3px 7px 3px 7px;
}
.beerInfo {
    display: flex;
    justify-content: space-between;
    /*text-align: st+art;*/
    font-size: 1vmin;
    margin: 3px 10px 3px 10px;
}