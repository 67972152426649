@media screen and (max-device-width: 640px) {
    .craftBeerContainer {
        width: 100%;
        /*padding: 20px;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .craftBeerContainer img {
        padding-bottom: 20px;
        width: inherit;
        height: auto;
    }
}
