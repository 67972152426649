.headerBar {
    font-family: Bely;
    border-bottom: 1px solid #db5791;
    cursor: pointer;
    position: fixed;
    padding: 10px;
    height: 40px;
    width: calc(100vw - 20px);
    background: white;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    top: -75px;
}

.headerBar a {
    text-decoration: none;
    color: #074524;
    font-style: oblique;
    font-size: 2.5vh;
    transition: 0.3s;
}
.headerBar a:hover {
    color: #10af5a;
    transition: 0.3s;
}

.headerBar img {
    height: inherit;
    object-fit: cover;
}

@keyframes appear {
    0% {
        top: -75px;
    }
    100% {
        top: 0px;
    }
}

@keyframes disappear {
    0% {
        top: 0px;
    }
    100% {
        top: -75px;
    }
}