@font-face {
    font-family: BelyDisplay;
    src: url("BelyDisplayRegular.otf") format("opentype");
}
@font-face {
    font-family: Bely;
    src: url("BelyRegular.otf") format("opentype");
}
@font-face {
    font-family: Bely;
    font-weight: bold;
    src: url("BelyBold.otf") format("opentype");
}
@font-face {
    font-family: Bely;
    font-style: italic;
    src: url("BelyItalic.otf") format("opentype");
}
@font-face {
    font-family: Bely;
    font-weight: bold;
    font-style: italic;
    src: url("BelyBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: KCIllHand;
    src: url("KCIllHand.otf") format("opentype");
}

@font-face {
    font-family: KCLisaHand;
    src: url("KCLisaHand.otf") format("opentype");
}

@font-face {
    font-family: KCWookieScript;
    src: url("KCWookieScript.otf") format("opentype");
}

@font-face {
    font-family: Conformity;
    src: url("Conformity.ttf") format("truetype");
}

@font-face {
    font-family: Another;
    src: url("Another_.ttf") format("truetype");
}