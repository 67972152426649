@media screen and (max-device-width: 1400px) and (min-width: 640px) {
    .googleMap {
        width: calc(95vw - 60px);
        height: 45vw;
    }

    .mapText {
        font-size: 3vh;
    }

    .openingHoursContainer {
        font-size: 2.2vw;
    }

    .showChristmasBtn {
        font-size: 2vh !important;
    }
}