@media screen and (max-width: 640px) {
    h1 {
        font-size: 12vw;
        margin-top: 50px;
        margin-bottom: 25px;
    }

    .eventsTitle {
        margin-top: 25px;
    }

    .landingImg {
        height: 100svh !important;
        object-fit: cover;
        margin-top: -30px;
        /*object-position: 53% 50%;*/
    }

    .landingAnim {
        opacity: 0;
        animation: slideUp 1s ease-in;
    }
    @keyframes slideUp {
        0% {
            opacity: 1;
            margin-top: 0
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            margin-top: -500%;
        }
    }

    /*.socialsContainer {*/
    /*    width: 75%;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    justify-content: space-evenly;*/
    /*    height: 50px;*/
    /*}*/

    behold-widget {
        padding: 20px;
    }

    .socialsContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 6vh;
    }

    .footer {
        font-size: 1.4vh;
        padding: 10px 5px 10px 5px;
        font-family: Bely, serif;
        color: #7a7a7a;
    }
    .footerDocsContainer {
        padding-top: 0;
    }
    .middleText {
        margin: 0 0.5rem; /* Adjust the margin as needed */
    }
    .belowWall {
        padding-bottom: 25px;
    }
}

