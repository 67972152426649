@media screen and (max-device-width: 640px) {
    .star {
        font-size: 1.75vw;
        height: 4.5vw;
        width: 4.5vw;
        margin-top: -10vw;
        margin-right: -10vw;
    }

    /*.starCard {*/
    /*    font-size: 3.75vw;*/
    /*    margin-right: 0;*/
    /*    !*margin-top: -35px;*!*/
    /*    right: 8vw;*/
    /*    top: 16vw;*/
    /*    height: 10vw;*/
    /*    width: 10vw;*/
    /*}*/
}