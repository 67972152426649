.bookingContainer {
    height: 100svh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bookingHeader {
    margin-top: 25px;
    margin-bottom: 10px;
}
.bookingInfoHeader {
    text-decoration: underline;
    font-size: 3.5vh;
    color: #084525;
    font-family: 'Bely', sans-serif;
    margin-top: 10px;
    margin-bottom: 0px;
}
.bookingInfoContainer {
    max-width: 550px;
    width: 50svw;
    gap: 10px;
    display: flex;
    flex-direction: column;
    font-size: 2.3vh;
    font-family: Bely, sans-serif;
    align-items: start;
    text-align: start;
}
.bookingBtnContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    gap: 10px;
}
.bookingBtn {
    width: 100%;
    font-family: Bely, sans-serif;
    font-size: 2vh;
}



.identityContainer {
    font-family: Bely, sans-serif;
    font-size: 2.3vh;
    display: flex;
    flex-direction: column;
    height: 100svh;
    max-width: 500px;
    width: 100svw;
    align-items: center;
    justify-content: center;
}

.identityContainer input {
    font-size: 2.3vh;
    font-family: Bely, sans-serif;
    border: grey 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin: 10px;
    box-sizing: border-box;
    z-index: 2;
}