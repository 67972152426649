@media screen and (max-width: 1200px) and (min-width: 640px) {
    .star {
        font-size: 1.2vw;
        height: 2.75vw;
        width: 2.75vw;
        margin-top: -6vw;
        margin-right: -6vw;
    }

    /*.starCard {*/
    /*    font-size: 1.5vw;*/
    /*    position: relative;*/
    /*    float: right;*/
    /*    margin-right: 0;*/
    /*    margin-top: -35px;*/
    /*    right: 5vw;*/
    /*    top: 4.5vh;*/
    /*    height: 4.25vw;*/
    /*    width: 4.25vw;*/
    /*}*/
}