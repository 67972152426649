@media screen and (max-width: 640px) {
    .grid-item > .beerPourLiquidRow1, .beerPourLiquidRow2, .beerRemoveLiquidRow1, .beerRemoveLiquidRow2,
    .beerPourLiquidRow3, .beerRemoveLiquidRow3, .beerPourLiquidRow4, .beerRemoveLiquidRow4, .beerPourLiquidRow5, .beerRemoveLiquidRow5 {
        display: flex;
        position: relative;
        z-index: 1;
        width: calc(1.3vw);
        /*width: 5px;*/
        margin-top: 48vw;
        margin-right: -20vw;
        background-color: #092215;
    }

    .grid-item > .beerPourLiquidEdge, .beerRemoveLiquidEdge {
        /*width: 5px;*/
        margin-top: 27vw;
        margin-right: -140vw;
        background-color: #092215;
    }

    .beerPourLiquidRow1, .beerRemoveLiquidRow1  {
        height: 192vw;
    }
    .beerPourLiquidRow2, .beerRemoveLiquidRow2 {
        height: 152vw;
    }
    .beerPourLiquidRow3, .beerRemoveLiquidRow3 {
        height: 112vw;
    }
    .beerPourLiquidRow4, .beerRemoveLiquidRow4 {
        height: 72vw;
    }
    .beerPourLiquidRow5, .beerRemoveLiquidRow5 {
        height: 32vw;
    }

    .beerPourLiquidRow1 {
        opacity: 1;
        animation: pourTheBeerRow1 1.5s ease-in;
    }
    .beerPourLiquidRow2 {
        opacity: 1;
        animation: pourTheBeerRow2 1.2s ease-in;
    }
    .beerPourLiquidRow3 {
        opacity: 1;
        animation: pourTheBeerRow3 1s ease-in;
    }
    .beerPourLiquidRow4 {
        opacity: 1;
        animation: pourTheBeerRow4 0.9s ease-in;
    }
    .beerPourLiquidRow5 {
        opacity: 1;
        animation: pourTheBeerRow5 0.75s ease-in;
    }

    .beerRemoveLiquidRow1, .beerRemoveLiquidRow2, .beerRemoveLiquidRow3, .beerRemoveLiquidRow4, .beerRemoveLiquidRow5 {
        opacity: 0;
        animation: stopTheBeer 0.2s ease-in;
    }

    @keyframes pourTheBeerRow1 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 192vw;
        }
    }

    @keyframes pourTheBeerRow2 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 152vw;
        }
    }

    @keyframes pourTheBeerRow3 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 112vw;
        }
    }

    @keyframes pourTheBeerRow4 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 72vw;
        }
    }

    @keyframes pourTheBeerRow5 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 32vw;
        }
    }

    @keyframes stopTheBeer {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}