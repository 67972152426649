.grid-item > .beerPourLiquidRow1, .beerPourLiquidRow2, .beerRemoveLiquidRow1, .beerRemoveLiquidRow2 {
    display: flex;
    position: relative;
    z-index: 1;
    width: calc(0.35vw);
    margin-top: 27vh;
    margin-right: -11vh;
    background-color: #092215;
}

.beerPourLiquidRow1 {
    height: 39vh;
}
.beerPourLiquidRow2 {
    height: 17vh;
}

.beerPourLiquidRow1 {
    opacity: 1;
    animation: pourTheBeerRow1 1.25s ease-in;
}
.beerPourLiquidRow2 {
    opacity: 1;
    animation: pourTheBeerRow2 0.75s ease-in;
}

.beerRemoveLiquidRow1, .beerRemoveLiquidRow2 {
    opacity: 0;
    animation: stopTheBeer 0.2s ease-in;
}

.beerRemoveLiquidRow1 {
    height: 39vh;
}
.beerRemoveLiquidRow2 {
    height: 17vh;
}

@keyframes pourTheBeerRow1 {
    0% {
        opacity: 0;
        height: 0;
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        height: 39vh;
    }
}

@keyframes pourTheBeerRow2 {
    0% {
        opacity: 0;
        height: 0;
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        height: 17vh
    }
}

@keyframes stopTheBeer {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}