.updateHeroContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.updateImagesContainer {
    flex-wrap: wrap;
    width: 95vw;
    display: flex;
    justify-content: center;
    gap: 10px;
}