@media screen and (max-width: 640px) {
    .heroImagesContainer {
        padding: 25px 0 0px 0;
        width: 100vw;
        margin-bottom: 50px;
    }

    .slide {
        margin: 0 0 0 25px;
        width: calc(100% - 50px);
        height: 100%;
        /*padding: 0px;*/
        /*margin: 25px;*/
        /*border-radius: 40px;*/
        object-fit: cover;
        aspect-ratio: 1;
    }
}