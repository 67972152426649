.craftBeerContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 45px;
    padding-bottom: 45px;
}
.craftBeerContainer img {
    width: 40%;
    cursor: pointer;
    filter: brightness(1);
    transition: 0.2s;
    border-radius: 10px;
}
.craftBeerContainer img:hover {
    cursor: pointer;
    filter: brightness(0.9);
    transition: 0.2s;
}
.craftBeerText {
    font-family: Bely, serif;
    font-size: 3vh;
    margin: 0;
}

.craftBeerOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px 30px 30px;
}