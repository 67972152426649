.beerAdminContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
}

.beerSelectionContainer {
    display: flex;
    flex-direction: column;
    height: 62svh;
    width: 100svw;
}
.beerSelectionFooter {
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.beerFooterSearchInfo, .beerFooterSaveInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.beersLogoList {
    background-color: #e8e8e8;
    margin: 20px;
    border-radius: 10px;
    overflow-y: scroll;
    padding: 10px;
    height: 39svh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}
.foundBeerContainer {
    display: flex;
    flex-direction: column;
}
.beersLogoList img {
    aspect-ratio: 1;
    border-radius: 50%;
    width: calc(100% - 50px);
    padding: 25px;
    /*height: auto;*/
    filter: grayscale(0%) brightness(100%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(1);
    transition: 0.2s;
}
.beersLogoList img:hover, .basketBeerImg:hover {
    cursor: pointer;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
    transition: 0.2s;
}

.basketBeerImg:hover {
    transition: 0.2s;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}

.basketContainer {
    /*border: 1px black solid;*/
    border-radius: 10px;
    margin: 18px 0 0 18px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: center;
    width: calc(100vw - 40px);
    /*height: calc(36vh);*/
    background-color: #e2e2e2;
}
.itemsInBasket {
    display: flex;
    flex-direction: row;
}
.beerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px #000000 solid;
    height: 30svh;
    justify-content: space-between;
    padding: 20px;
    width: 150px;
}
.basketBeerImg {
    filter: grayscale(0%) brightness(100%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(1);
    transition: 0.2s;
    border-radius: 50%;
    margin: 10px;
    height: calc(20vh - 75px);
    aspect-ratio: 1 / 1;
}


.bottomOfBasket {
    border-radius: 0 10px 10px 0;
    margin: 0px 18px 0px 0px;
    /*height: inherit;*/
    height: calc(27svh + 54px);
    color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    width: 94px;
    right: 0;
    z-index: 2;
    background-color: #7a7a7a;
    bottom: 0;
    padding: 5px;
}

.saveBeerBtn {
    padding: 5px;
}

.loading {
    color: white;
    font-size: 5vh;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.positionInput {
    background-color: #e8e8e8;
    width: 50px;
}

.adminBeerBtnImg {
    width: 35px;
    height: 35px;
    object-fit: scale-down;
    filter: invert(1);
}

.beerBtnContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.beerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 40px;
    height: 40px;
    margin-top: 0;
}

.sketchPickerBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 5px;
    gap: 5px;
}

.beersLoadingContainer {
    width: 100%;
    height: inherit;
}