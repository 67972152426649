@media screen and (max-width: 640px) {
    #Layer_2 {
        width: 100% !important;
    }

    .quizTablesContainer {
        flex-direction: column;
    }

    .layoutBtnContainer {
        flex-direction: column;
    }

    .updateQuizInnerContainer {
        flex-direction: column;
    }
}