.beerLogoImg {
    object-fit: cover;
    border-radius: 50%;
    filter: brightness(100%);
    transition: 0.2s;
}
.beerLogoImg:hover {
    cursor: pointer;
    filter: brightness(55%);
    transition: 0.2s;
}
.grid-item > .beerOnTapLetter {
    /*height: calc(100% - 30px);*/
    width: auto;
}


.hidden {
    display: none;
}

.loadingPlaceholder {
    border-radius: 50%;
    height: calc(100% - 20px);
    width: auto;
    background-color: #ccc;
    animation: loadingImg 1s ease-in-out infinite;
}

@keyframes loadingImg {
    0% {
        background-color: #ccc;
    }
    50% {
        background-color: #ddd;
    }
    100% {
        background-color: #ccc;
    }
}