.heroImagesContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 25px 0 100px 0;
}

.slide {
    cursor: grab;
    border-radius: 10px;
    margin: 0 12px 0 12px ;
    height: 25vw;
    width: auto;
    object-fit: contain;
}

.slick-arrow {
    position: absolute;
    z-index: 4;
}
.slick-arrow:before {
    opacity: 1 !important;
    color: #06341c !important;
}
.slick-next {
    right: 15px !important;
}
.slick-prev {
    left: 15px !important;
}