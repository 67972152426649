@media screen and (max-width: 1400px) {
    .eventsContainer {
        /*display: grid;*/
        /*justify-content: center;*/
        /*justify-items: center;*/
        /*grid-template-columns: repeat(3, calc(30vw));*/
        /*grid-template-rows: repeat(2, calc(30vw));*/
    }

    .posterContainer {
        height: 24vw;
        width: 18vw;
        aspect-ratio: 4/3;
    }
}

@media screen and (max-device-width: 640px) {
    .eventsContainer {
        width: 100svw;
        /*display: grid;*/
        /*justify-content: center;*/
        /*justify-items: center;*/
        /*grid-template-columns: repeat(2, calc(45vw));*/
        /*grid-template-rows: repeat(3, calc(50vw));*/
    }

    .posterContainer {
        height: 37vw;
        width: 29vw;
        aspect-ratio: 4/3;
    }
}