@media screen and (max-width: 640px) {
    .beerCardInnerContainer {
        width: 60vw;
    }

    .beerCardInnerContainer h1 {
        margin-top: 10px;
        font-size: 3.3vh;
    }

    .beerCardBrewery {
        font-size: 2vh;
    }
    .beerCardBreweryLocation, .beerCardBreweryCountry {
        font-size: 1.75vh;
    }
    .beerCardInfo {
        font-size: 1.5vh;
    }
    .beerCardDescription {
        font-size: 1.75vh;
    }

    .untappdBtn {
        font-size: 1.75vh;
    }

    .beerCardCloseButton {
        font-size: 2.25vh;
    }
}