@media screen and (max-device-width: 640px) {
    .visitUsHeader {
        margin-top: 40px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 15px;
    }
    .visitUsHeader h1 {
        margin: 0 0 15px;
    }

    .openingHoursContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        font-size: 4.3vw !important;
        margin-left: 0px;
    }
    .openingHoursContainer div:nth-child(1), .openingHoursContainer div:nth-child(2), .openingHoursContainer div:nth-child(3), .openingHoursContainer div:nth-child(4), .openingHoursContainer div:nth-child(5), .openingHoursContainer div:nth-child(6) {
        column-gap: 5px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }
    .hourText {
        margin-right: 10px;
    }

    .googleMap {
        width: 90vw;
        height: 40vh;
    }
    .mapText {
        font-size: 2rem;
    }

    .showChristmasBtn {
        font-size: 2vh !important;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}
