@media screen and (max-width: 640px) {
    .quizConfirmImg {
        /*max-width: 250px !important;*/
    }
    .quizBottomImg {
        /*margin: 20px 0 20px 0 !important;*/
        width: 80% !important;
    }
    .bookingDetailsMessage {
        font-size: 1.25rem !important;
    }
    .bookingDetailsContainer {
        font-size: 1.25rem !important;
    }
}