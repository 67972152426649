

.star {
    align-items: center;
    background-color: #DB5791;
    display: flex;
    font-family: 'KCIllHand', sans-serif;
    color: white;
    font-size: 1vmin;
    height: 2.5vh;
    width: 2.5vh;
    justify-content: center;
    position: absolute;
    transform: rotate(45deg);
    z-index: 3;
    margin-top: -5vh;
    margin-right: -5vh;
    animation: slowRotate 30s linear infinite;

    &::before, &::after {
        background-color: #DB5791;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &::before {
        transform: rotate(30deg);
    }

    &::after {
        transform: rotate(60deg);
    }

    p {
        transform: rotate(-25deg);
        animation: slowRotateText 30s linear infinite;
    }
}

@keyframes slowRotate {
    0% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(225deg);
    }
    100% {
        transform: rotate(405deg);
    }
}

@keyframes slowRotateText {
    0% {
        transform: rotate(-25deg);
    }
    50% {
        transform: rotate(-205deg);
    }
    100% {
        transform: rotate(-385deg);
    }
}


.starCard {
    font-size: 1.15vw;
    position: relative;
    /*float: right;*/
    margin-top: 0px;
    margin-right: 0;
    /*right: 2vw;*/
    /*top: 8vh;*/
    height: 2.75vw;
    width: 2.75vw;
}