@media screen and (max-device-width: 640px) {
    .adminBtn, .adminInput {
        font-size: 16px;
    }

    .beersLogoList {
        grid-template-columns: repeat(4, 1fr);
        padding: 20px;
        height: calc(77svh - 40px);
    }

    .beersLogoList img {
        width: calc(100% - 10px);
        padding: 5px;
    }
}