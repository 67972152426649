.beerCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    cursor: pointer;
    pointer-events: visiblePainted;
    opacity: 0%;
    transition: opacity 0.25s;
}

.beerCardInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 200vh;
    border: #DB5791 calc(11vh / 15) solid;
    border-radius: 25px;
    background-color: #fefefe;
    width: 16vw;
    height: fit-content;
    max-height: 70svh;
    cursor: auto;
    padding: 25px;
    transition: margin-top 0.75s;
    overflow-y: auto;
}
/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 0px;
}
.beerCardInnerContainer h1 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 2vw;
}

.beerCardCloseButton {
    cursor: pointer;
    padding: 15px;
    font-size: 1.25vw;
    font-family: KCIllHand, sans-serif;
    position: relative;
    float: right;
    margin: -15px -15px -30px -15px;
    width: fit-content;
}


.beerCardImg {
    object-fit: cover;
    aspect-ratio: 1;
    margin: 0px 25px 10px 25px;
    width: calc(100% - 50px);
    border-radius: 50%;
}
.beerCardBrewery, .beerCardInfo {
    font-family: KCIllHand, sans-serif;
}
.beerCardBrewery {
    font-size: 1.2vw;
    margin-bottom: 5px;
}
.beerCardBreweryLocation {
    margin-top: 10px;
}
.beerCardBreweryLocation, .beerCardBreweryCountry {
    font-size: 1vw;
}
.beerCardInfo {
    font-size: 0.8vw;
}
.beerCardDescription {
    text-align: start;
    font-size: 1vw;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Bely, serif;
}
.beerCardInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.untappdBtn {
    border-radius: 10px;
    font-size: 1.25vw;
    font-family: KCIllHand, 'sans-serif';
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    background-color: #ffc000;
    transition: 0.3s;
    border: #c99600 2px solid;
}
.untappdBtn:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: #dea200;
}