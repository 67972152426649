.grid-container {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(11, calc(11vh));
    grid-template-rows: repeat(7, calc(11vh));
    width: 100svw;
    padding: 50px;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
    justify-content: center;
    /*cursor: url('../../imgs/beerontap/pint-glass-cursor.svg') 16 16, auto;*/
    /*transform-origin: top left;*/
    /*cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3e%3cg transform='rotate(45 256 256)'%3e%3crect id='r' x='16' y='216' width='480' height='80' rx='14'/%3e%3cuse href='%23r' transform='rotate(90 256 256)'/%3e%3c/g%3e%3c/svg%3e") 16 16, pointer;*/
    /*cursor: pointer;*/
    user-select: none; /* Standard syntax */
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: calc(11vh / 15) solid #DB5791;
    box-sizing: border-box;
    padding: 0;
    margin: calc(11vh / -30);
}

.beerOnTapLetter, .beerLogoImg, .loadingPlaceholder {
    height: calc(100% - 20px);
    aspect-ratio: 1;
    /*padding: 15px;*/
}

.tap-and-liquid {
    display: flex;
    align-items: flex-start;
}