@media screen and (max-width: 1200px) and (min-width: 640px) {
    .grid-item > .beerPourLiquidRow1, .beerPourLiquidRow2, .beerRemoveLiquidRow1, .beerRemoveLiquidRow2,
    .beerPourLiquidRow3, .beerRemoveLiquidRow3 {
        display: flex;
        position: relative;
        z-index: 1;
        width: calc(0.9vw);
        /*width: 5px;*/
        margin-top: 29vw;
        margin-right: -12vw;
        background-color: #092215;
    }

    .grid-item > .beerPourLiquidEdge, .beerRemoveLiquidEdge {
        /*width: 5px;*/
        margin-top: -7vw;
        margin-right: -156vw;
        background-color: #092215;
    }

    .beerPourLiquidRow1, .beerRemoveLiquidRow1  {
        height: 67vw;
    }
    .beerPourLiquidRow2, .beerRemoveLiquidRow2 {
        height: 43vw;
    }
    .beerPourLiquidRow3, .beerRemoveLiquidRow3 {
        height: 19vw;
    }

    .beerPourLiquidRow1 {
        opacity: 1;
        animation: pourTheBeerRow1 1.2s ease-in;
    }
    .beerPourLiquidRow2 {
        opacity: 1;
        animation: pourTheBeerRow2 0.9s ease-in;
    }
    .beerPourLiquidRow3 {
        opacity: 1;
        animation: pourTheBeerRow3 0.6s ease-in;
    }

    .beerRemoveLiquidRow1, .beerRemoveLiquidRow2, .beerRemoveLiquidRow3 {
        opacity: 0;
        animation: stopTheBeer 0.2s ease-in;
    }

    @keyframes pourTheBeerRow1 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 67vw;
        }
    }

    @keyframes pourTheBeerRow2 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 43vw;
        }
    }

    @keyframes pourTheBeerRow3 {
        0% {
            opacity: 0;
            height: 0;
        }
        10% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            height: 19vw;
        }
    }

    @keyframes stopTheBeer {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}