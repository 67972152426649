@media screen and (max-width: 640px) {
    .grid-container {
        padding: 50px 50px 25px 50px;
        grid-template-columns: repeat(4, calc(20vw));
        grid-template-rows: repeat(15, calc(20vw));
    }
    .grid-item {
        border: calc(20vw / 15) solid #DB5791;
        margin: calc(20vw / -30) ;
    }

    /* BEER ON TAP */
    .grid-item > .beerOnTapLetter {
        height: calc(20vw - 30px) !important;
        width: calc(20vw - 30px) !important;
    }

    .beerLogoImg, .beerOnTapLetter, .loadingPlaceholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(20vw - 20px) !important;
        width: calc(20vw - 20px) !important;
        padding: 0;
    }
}