#Layer_2 {
    width: 25rem;
}

.cls-1 {
    stroke: #000;
    stroke-width: 1px;
}

.cls-1, .cls-2, .cls-3, .cls-4 {
    stroke: #000;
    stroke-width: 1px;
    fill: none;
}

.cls-1, .cls-2, .cls-4 {
    stroke-miterlimit: 10;
}

.cls-2 {
    stroke: #000;
    stroke-width: 2px;
}

.cls-6, .cls-3, .cls-7 {
    stroke-width: 1px;
}

.cls-8 {
    opacity: .48;
}

.cls-4 {
    fill: #bbb;
    stroke: #000;
}

.cls-5, .cls-3 {
    fill: #fff;
}