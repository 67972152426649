.teku {
    bottom: 50px;
    right: 50px;
    display: flex;
    position: fixed;
    height: 13vh;
    z-index: 3;
    transition: transform 0.3s;
}
.teku:hover {
    cursor: pointer;
}

@keyframes anim {
    100% {
        stroke-dashoffset: -235;
    }
}

#pth {
    animation: anim 5s linear infinite;
}




.t-1 {
    fill: none;
    stroke: #231f20;
    stroke-miterlimit: 10;
    stroke-width: 14px;
}

.t-2 {
    opacity: .95;
}

/*.t-3 {*/
/*    fill: #fff200;*/
/*}*/

.t-3, .t-4 {
    stroke-width: 0px;
    transition: fill 0.2s;
}

.t-4 {
    fill: #231f20;
}

@keyframes fill-animation {
    from {
        height: 55%;
    }
    to {
        height: 0%;
    }
}

#filling-rect {
    transition: height 2s ease-out;
}

.dragMeLabel {
    font-family: KCIllHand, sans-serif;
    font-size: 2vh;
    color: #0b713c;
    position: fixed;
    top: 80%;
    left: 92.5%;
}