.adminSignInContainer {
    font-size: 16px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signInFields {
    font-size: 18px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.signInFields input {
    font-size: 16px;
}
.signInFields > p {
    margin: 20px 0 5px 0;
}
.signInFields button {
    margin-top: 10px;
    width: 100%;
}