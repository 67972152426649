@media screen and (max-width: 1400px) and (min-width: 640px) {
    .landingImg {
        object-fit: cover !important;
    }

    h1 {
        margin-top: 35px;
        font-size: 5.5vh;
    }

    .eventsTitle {
        margin-top: 25px;
    }

    .socialsContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 9vh;
    }
    .socialImg {
        max-height: 100px;
    }

    .footer {
        font-size: 1.5vh;
        padding: 30px 30px 20px 30px;
        font-family: Bely, serif;
        color: #7a7a7a;
    }
    .footerDocsContainer {
        padding-top: 0;
    }
}