.quizDialogInnerContainer h1 {
    font-size: 50px;
    margin-top: 0;
    margin-bottom: 25px;
}

.quizFieldsContainer {
    width: 86%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.quizFieldsContainer input, .quizFieldsContainer select {
    font-size: 18px;
    font-family: Bely, sans-serif;
    border: grey 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin: 10px;
    box-sizing: border-box;
    z-index: 2;
}
.teamTitle {
    width: 99%;
    text-align: start;
    opacity: 25%;
    margin-bottom: -10px;
    font-size: 12px !important;
    font-family: Bely, sans-serif;
    z-index: 1;
}
.quizBtn {
    box-sizing: border-box;
    letter-spacing: 3px;
    font-family: KCIllHand, sans-serif;
    font-size: 24px !important;
    width: 86% !important;
    margin-top: 10px;
    padding: 10px !important;
}
.quizBtn:disabled, .quizBtn:disabled:hover {
    background-color: gray;
    cursor: not-allowed;
}

.quizBtnContainer {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.contactQuiz {
    width: 86%;
    color: #1e1e1e;
    font-style: oblique;
    margin-top: 15px;
    font-size: 18px !important;
    font-family: Bely, sans-serif;
}


.bookAnotherContainer {
    align-items: center;
    font-size: 20px !important;
    font-family: Bely, sans-serif;
    padding: 15px 0 15px 0;
    gap: 10px;
    font-style: oblique;
    display: flex;
    flex-direction: row;
}
.bookAnotherBtn {
    line-height: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 22px !important;
    font-family: KCIllHand, sans-serif;
    padding: 8px 0 0 0 !important;
    margin: 0 !important;
}

.bookAnotherPlus {
    background-color: #06341c !important;
    transition: 0.2s;
}
.bookAnotherPlus:disabled {
    background-color: #707070 !important;
    transition: 0.2s;
}
.bookAnotherMinus {
    background-color: #da3838 !important;
}
.bookAnotherMinus:disabled {
    background-color: #707070 !important;
    transition: 0.2s;
}

.soldOutMessage {
    font-size: 18px;
    font-family: Bely, sans-serif;
}