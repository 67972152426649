@media screen and (max-device-width: 640px) {
    .updateEventsContainer {
        display: flex;
        flex-direction: column;
        /*grid-template-columns: repeat(1, calc(100vw));*/
        /*grid-auto-rows: max-content;*/
        /*padding: 10px;*/
    }

    .updatePosterContainer {
        height: fit-content;
        width: calc(100% - 50px);
        padding: 25px;
        border: none;
        border-bottom: gray 1px solid;
    }

    .updatePosterContainer img {
        height: 75svw;
    }

    .updatePosterContainer:hover {
        border: none;
        border-bottom: gray 1px solid;
    }
}