@media screen and (max-width: 640px) {
    .quizBtnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .quizDialogInnerContainer h1 {
        font-size: 2.3rem;
    }


    .quizFieldsContainer input, .quizFieldsContainer select {
        -webkit-appearance: none;
        font-size: 1.2rem;
    }
    .teamTitle {
        font-size: 1rem !important;
    }
    .quizBtn {
        font-size: 1.5rem !important;
    }
    .contactQuiz, .soldOutMessage {
        font-size: 1.2rem !important;
    }
    .bookAnotherContainer {
        font-size: 1.3rem !important;
    }
}