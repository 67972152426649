@media screen and (max-width: 640px) {
    .identityContainer {
        max-width: none;
        width: 80svw;
    }

    .bookingInfoContainer {
        max-width: none;
        width:80svw;
    }
}