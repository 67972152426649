.tableInfoContainer {
    border-radius: 10px;
}

.tableInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 5px;
    text-align: start;
    font-size: 12px;
    justify-content: start;
    gap: 5px;
}
.tableInfo input {
    margin: 0;
}

.swapTeamBtn {
    font-size: 9px;
    padding: 0;
    margin: 0;
}