/* LANDING */
.landingContainer {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    width: 100vw;
    height: 100svh;
    z-index: 999;
    overflow: hidden;
}
.landingImg {
    margin-top: -10px;
    /*max-width: 100%;*/
    overflow: hidden;
    cursor: pointer;
    width: 100vw;
    /*object-position: 42% 50%;*/
    height: inherit;
    object-fit: cover;
    scale: 1.1;
}

/* HOME */
.homeContainer {
    max-width: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

h1 {
    margin-top: 75px;
    margin-bottom: 50px;
    font-size: 5vw;
    color: #084525;
    font-family: 'KCIllHand', sans-serif
}

.wallContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.belowWall {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

/* SOCIALS */
.socialsContainer {
    width: 75vw;
    max-width: 1250px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 8vh;
}
.socialImg {
    filter: invert(19%) sepia(10%) saturate(6329%) hue-rotate(110deg) brightness(92%) contrast(95%);
    transition: 0.2s;
    cursor: pointer;
    height: inherit;
    border-radius: 10px;
}
.socialImg:hover {
    filter: invert(19%) sepia(10%) saturate(6329%) hue-rotate(110deg) brightness(100%) contrast(50%);
    transition: 0.2s;
}

.contactUs {
    font-size: 2.75vh;
    padding: 10px;
    font-family: Bely, serif;
    color: #000000;
}
.footer {
    font-size: 1vw;
    padding: 30px 30px 20px 30px;
    font-family: Bely, serif;
    color: #7a7a7a;
}
.footerLink {
    text-decoration: underline;
    color: -webkit-link !important;
    cursor: pointer;
}

.footerDocsContainer {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.leftText {
    flex: 1 1;
    margin-right: auto;
    text-align: right;
}
.middleText {
    margin: 0 1rem;
}
.rightText {
    flex: 1 1;
    margin-left: auto;
    text-align: left;
}

.eventsTitle {
    margin-top: 50px;
}