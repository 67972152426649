.eventsContainer {
    width: 90svw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    /*justify-items: center;*/
    /*grid-template-columns: repeat(3, calc(20vw));*/
    /*grid-template-rows: repeat(2, calc(20vw));*/
    margin-top: 30px;
}

.posterContainer {
    height: 16vw;
    width: 12vw;
    aspect-ratio: 4/3;
    border: 2px solid #DC5792;
    transition: 0.2s;
}
.posterContainer:hover {
    cursor: pointer;
    border: 5px solid #DC5792;
    transition: 0.2s;
}

.posterContainer img {
    object-fit: cover;
    width: inherit;
    height: inherit;
    background-size: cover;
    background-position: center;
}

.texture {
    position: absolute;
    z-index: 2;
    mix-blend-mode: darken;
    opacity: 40%;
}