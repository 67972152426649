@media screen and (max-width: 640px) {
    .basketBeerImg {
        /*margin: 0;*/
    }

    .beersLogoList {
        padding: 10px;
        grid-template-columns: repeat(2, 1fr);
        margin: 10px
    }

    .beersLogoList img {
        /*padding: 10px;*/
    }

    .beerItem {
        height: calc(30svh - 10px);
        width: 150px;
        padding: 10px;
        font-size: 1.5vh;
    }

    .beerSelectionFooter {
        flex-direction: column;
    }
    .basketContainer {
        margin: 20px;
        width: calc(100% - 40px);
        border-radius: 10px 10px 0 0;
    }
    .bottomOfBasket {
        margin: 20px;
        width: calc(100% - 50px);
        height: 50px;
        border-radius: 0 0 10px 10px;
    }
    .beerAdminContainer {
        padding-bottom: 0px;
    }


    /*.adminBeerBtnImg {*/
    /*    width: 35px;*/
    /*    height: 35px;*/
    /*    object-fit: scale-down;*/
    /*    filter: invert(1);*/
    /*}*/
    /*.beerButton {*/
    /*    !*width: 30px;*!*/
    /*    !*height: 40px;*!*/
    /*}*/
}