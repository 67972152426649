.grid-item > .tap {
    position: relative;
    max-height: 16vh;
    cursor: pointer;
    margin-top: 5%;
    margin-right: 105%;
    z-index: 2;
    /*filter: brightness(1);*/
    /*transition: 0.2s;*/
}
.tap:hover {
    /*filter: brightness(0.55);*/
    /*transition: 0.2s;*/
}