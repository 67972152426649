.adminContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.adminHomeBtn {
    margin-top: 10px;
    font-family: KCIllHand;
    padding: 15px;
    font-size: 7vh !important;
    width: 100%;
}

.adminHeader {
    margin: 20px 0 0 0;
    font-size: 5vh;
}

.adminInput {
    border: 1px gray solid;
    background: none;
    padding: 7px;
    border-radius: 8px;
}

.adminBtn {
    font-size: 0.9rem;
    /*border: 1px #052a17 solid;*/
    border: none;
    padding: 10px;
    background-color: #084525;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    transition: 0.2s;
    filter: brightness(100%);
}

.adminLogoutBtn {
    font-size: 3vh !important;
}

@media (hover: hover) {
    .adminBtn:hover {
        filter: brightness(75%);
        transition: 0.2s;
        cursor: pointer;
    }
}

.adminBtn:active {
    filter: brightness(75%);
    transition: 0.2s;
    cursor: pointer;
}

.adminBtn:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
}