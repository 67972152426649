.fullPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pageHalf {
    max-height: 50%;
    width: 99%;
    height: 50%;
}
.half2 {
    font-size: 50px;
    font-family: 'BelyDisplay', sans-serif;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.pageLogo {
    margin-bottom: 25px;
    height: 40%;
}