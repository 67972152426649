@media screen and (max-width: 1200px) {
    .grid-item > .tap {
        max-height: 17vw;
        margin-top: 7% !important;
        margin-right: 105% !important;
    }

    .grid-item > .tap-edge {
        margin-top: 115% !important;
        margin-right: -107% !important;
    }
    .tap:hover {
        filter: brightness(1) !important;
        transition: 0.2s;
    }
}