@media screen and (max-width: 1200px) and (min-width: 640px) {
    .beerCardInnerContainer {
        width: 26vw;
    }

    .beerCardInnerContainer h1 {
        margin-top: 10px;
        font-size: 3.5vmin;
    }

    .beerCardBrewery {
        font-size: 1.9vmin;
    }
    .beerCardBreweryLocation, .beerCardBreweryCountry {
        font-size: 1.9vmin;
    }
    .beerCardInfo {
        font-size: 1.75vmin;
    }
    .beerCardDescription {
        font-size: 2vmin;
    }

    .untappdBtn {
        font-size: 1.5vmin;
    }
}