@media screen and (max-width: 1200px) and (min-width: 640px) {
    .grid-container {
        padding-right: 50px;
        grid-template-columns: repeat(7, calc(12vw));
        grid-template-rows: repeat(10, calc(12vw));
    }
    .grid-item {
        border: calc(12vw / 15) solid #DB5791;
        margin: calc(12vw / -30);
    }

    /* BEER ON TAP */
    .grid-item > .beerOnTapLetter {
        height: calc(12vw - 30px) !important;
        width: calc(12vw - 30px) !important;
    }

    .beerLogoImg, .beerOnTapLetter, .loadingPlaceholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(11vw - 20px) !important;
        width: calc(11vw - 20px) !important;
        padding: 0;
    }
}