/* VISIT US */
.visitUsHeader {
    font-family: Bely;
    color: #084525;
    margin-top: 70px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.visitUsHeader h1 {
    margin: 0;
}

.openingHoursContainer {
    /*width: 80%;*/
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/
    margin-top: 5px;
    font-size: 1.5vw;
    margin-left: 25px;
    /*display: grid;*/
    /*grid-template-columns: repeat(3, auto);*/
    justify-items: start;
}
.openingHoursContainer div {
    white-space: nowrap; /* Prevent wrapping */
    /*flex: auto 0 calc(33% - 10px);*/
}
.openingHoursContainer div:nth-child(1) {
    margin-bottom: 5px;
}
.openingHoursContainer div:nth-child(1), .openingHoursContainer div:nth-child(2), .openingHoursContainer div:nth-child(3) {
    column-gap: 5px;
    display: flex;
    flex-direction: row;
}
.hourText {
    margin-right: 10px;
}

.visitUsContainer {
    /*padding: 0px 30px 30px 30px*/
}
.googleMap {
    width: 65vw;
    height: 65vh;
    margin-bottom: 30px;
    border-radius: 10px;
}
.mapText {
    font-variant-numeric: lining-nums;
    font-family: Bely, serif;
    font-size: 5vh;
    margin: 0;
}


.showChristmasBtn {
    font-size: 2.5vh !important;
    /*margin-top: -5px;*/
}

.showChristmasBtn {
    background-color: #c20000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.showChristmasBtn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* Increased width and height for movement */
    height: 200%;
    background-image: var(--show);
    background-size: 10px 10px;
    opacity: 0.3;
    animation: diagonalMove 30s linear infinite; /* Added animation */
}

@keyframes diagonalMove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, -75%); /* Adjust these values for speed/angle */
    }
}